import React from 'react';
import * as ROUTES from '../../constants/routes';
import { XIcon } from '@heroicons/react/outline';
import LoggedUser from './LoggedUser';
import strings from '../../configs/strings.json';
import { DynamicLink } from '../Shared/DynamicLink';
import Logo from '../../assets/icons/elevo_logo_black.png';
function Sidebar({ showLinks, closeSidebar, user }) {
  return (
    <aside className={showLinks ? 'sidebar show-sidebar' : 'sidebar'}>
      <button
        className={showLinks ? 'close-modal-btn' : 'hide-modal-btn'}
        onClick={closeSidebar}
      >
        <XIcon className="w-10 h-10 text-red-warm" />
      </button>
      <ul className="flex flex-col items-center justify-center p-0 list-none">
        <figure className="width-34per mb-5">
          <img src={Logo} className="w-full h-full" alt="" />
        </figure>
        <LoggedUser user={user} />
        <hr className="border border-gray-platinum mt-5 w-2/3" />
        <li
          className="uppercase font-normal p-4 text-shadow hover:text-shadow hover:transition duration-500 ease-in-out tracking-wide cursor-pointer text-black-eel text-2xl"
          onClick={closeSidebar}
        >
          <DynamicLink to={ROUTES.DASHBOARD}>
            {strings.main_section_label}
          </DynamicLink>
        </li>
        <li
          className="uppercase font-normal p-4 text-shadow hover:text-shadow hover:transition duration-500 ease-in-out tracking-wide cursor-pointer text-black-eel text-2xl"
          onClick={closeSidebar}
        >
          <DynamicLink to={ROUTES.COMING_SOON}>
            {strings.playlist_label}
          </DynamicLink>
        </li>
        <li
          className="uppercase font-normal p-4 text-shadow hover:text-shadow hover:transition duration-500 ease-in-out tracking-wide cursor-pointer text-black-eel text-2xl"
          onClick={closeSidebar}
        >
          <DynamicLink to={ROUTES.PROGRESS_TRACKER}>
            {strings.progress_tracker_label}
          </DynamicLink>
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;
