import React from 'react';
import Description from './Description';
import VideoEmbed from './VideoEmbed';
import Actions from './Actions';
import Title from './Title';
import GradeName from './GradeName';
import Category from './Category';
import { useSelector } from 'react-redux';
import Date from './Date';
import Skeleton from 'react-loading-skeleton';
import ReactLoader from '../ReactLoader';

const Video = ({ loading, data }) => {
  const params = useSelector((state) => state.config.params);

  const { rendered: title } = data.title;
  const { rendered: content } = data.content;

  return (
    <>
      {loading ? (
        <div className="w-full 3xl:width-3xl h-full flex justify-center  ">
          <ReactLoader height={100} width={100} />
        </div>
      ) : (
        <VideoEmbed url={data.acf ? data.acf.video : null} params={params} />
      )}
      <div className="p-5 print:shadow-none shadow w-full bg-white-pure print:py-14 print:px-20">
        <div className="grid grid-cols-6 rounded">
          <div className="col-start-1 col-end-5  pb-3">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Title text={title} />
                <section className="block 500media:flex items-center opacity-75 w-80 500media:w-full mt-5 print:mt-0">
                  <Category id={data.sel_skill} />
                  <span className="inline-block px-2">|</span>
                  <GradeName id={data.education_group} />
                  <span className="inline-block px-2">|</span>
                  <Date strDate={data.modified} />
                </section>
              </>
            )}
          </div>
          <Actions />
        </div>
        <hr className="print:hidden pt-3" />
        {loading ? <Skeleton count={15} /> : <Description text={content} />}
      </div>
    </>
  );
};

export default Video;
