import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { storeUser } = authSlice.actions;
export default authSlice.reducer;
