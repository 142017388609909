import { useGetSkillByIdQuery } from '../../api/lessons';

const Category = ({ id: skills = [] }) => {
  const skip = skills.length == 0;
  const skill = skip ? null : skills[0];

  const { data: category, isLoading } = useGetSkillByIdQuery(skill, { skip });

  if (isLoading || !category) return null;
  return <span className="inline-block text-sm">{category.name}</span>;
};

export default Category;
