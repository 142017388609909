import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  orderBy,
} from 'firebase/firestore';

export default class Repository {
  collection = '';

  constructor(fs) {
    this.fs = fs;
  }

  getRef(id) {
    return doc(this.fs.db, this.collection, id);
  }

  async searchByField(field, value, comparator = '==', ...filters) {
    const q = query(
      collection(this.fs.db, this.collection),
      where(field, comparator, value),
      ...filters
    );

    const querySnapshot = await getDocs(q);
    const results = [];

    querySnapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    return results;
  }

  async searchOneByField(field, value, comparator = '==') {
    const result = await this.searchByField(field, value, comparator);
    return result.length > 0 ? result[0] : null;
  }

  async update(id, payload) {
    const docRef = doc(this.fs.db, this.collection, id);
    return setDoc(docRef, payload, { merge: true });
  }

  async delete(id) {
    return await deleteDoc(doc(this.fs.db, this.collection, id));
  }

  async getById(id) {
    const docRef = doc(this.fs.db, this.collection, id);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
  }
}
