const Button = ({ type, value }) => {
  return (
    <input
      type={type}
      value={value}
      className="block bg-blue-azure text-white-pure rounded-lg py-1 px-9 font-semibold mt-7"
    />
  );
};

export default Button;
