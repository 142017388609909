import { useSelector } from 'react-redux';

const IndependentStudyButton = () => {
  const params = useSelector((state) => state.config.params);

  return (
    <a
      href={'https://video.sportsforlearning.com/library?' + params}
      target="_blank"
      className="print:hidden"
    >
      <button
        className="block text-xs 500media:text-base 500media:block md:block py-1 px-3
          font-medium
          3xl:text-2xl
          rounded-full
          border
          border-white-pure
          text-white-pure
          bg-orange-header
          hover:text-orange-header
          hover:bg-white-pure md:mr-5"
      >
        Independent Study
      </button>
    </a>
  );
};

export default IndependentStudyButton;
