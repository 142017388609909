import React from 'react';
import { useGetGroupByIdQuery } from '../../api/lessons';

//date-fns do the trick
const GradeName = ({ id: groups = [] }) => {
  const skip = groups.length == 0;
  const group = skip ? null : groups[0];

  const { data: group_data, isLoading } = useGetGroupByIdQuery(group, { skip });

  if (isLoading || !group_data) return null;
  return <div className="inline-block text-sm">{group_data.name}</div>;
};

export default GradeName;
