import React from 'react';
import propTypes from 'prop-types';
import Category from './Category';
import { isDemoMode } from '../../configs/flags';
import { useSelector } from 'react-redux';

const Card = ({
  label,
  title,
  image,
  tag,
  tag_color,
  font_color,
  font_color_subtitle,
  link = '#',
  new_tab = false,
}) => {
  const params = useSelector((state) => state.config.params);
  const linkSeparator = params ? (link.indexOf('?') === -1 ? '?' : '&') : '';

  return (
    <a
      href={isDemoMode ? '#' : link + linkSeparator + params}
      target={new_tab ? '_blank' : '_self'}
      className="block pr-4"
    >
      <figure className="relative rounded-sm overflow-hidden text-white cursor-pointer w-full h-full">
        <span
          className={`absolute px-5 pt-3 3xl:px-8 3xl:py-5 uppercase text-xl md:text-base 3xl:text-xl ${
            font_color || 'text-white-pure'
          }`}
        >
          {label}
        </span>
        <h3
          className={`${
            font_color_subtitle || 'text-white-pure'
          } absolute bottom-14 text-3xl 3xl:text-hero-cards m-5 3xl:m-8 font-bold transform capitalize`}
        >
          {title}
        </h3>
        <img
          src={image.sizes.medium_large}
          alt=""
          className="w-full h-full object-cover text-white-pure"
        />
        <Category tag={tag} tag_color={tag_color} />
      </figure>
    </a>
  );
};
Card.propTypes = {
  label: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  tag: propTypes.string.isRequired,
  tag_color: propTypes.string.isRequired,
  image: propTypes.shape({
    sizes: propTypes.shape({
      medium_large: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default Card;
