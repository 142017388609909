import { Link } from 'react-router-dom';
import { isDemoMode } from '../../configs/flags';
import { useSelector } from 'react-redux';

export function DynamicLink(props) {
  const { force = false, to } = props;
  const uri = typeof to === 'string' ? to : to.pathname;

  const params = useSelector((state) => state.config.params);
  const linkSeparator = params ? (uri.indexOf('?') === -1 ? '?' : '&') : '';

  return (
    <Link
      {...props}
      to={uri + linkSeparator + params}
      onClick={(e) => !force && isDemoMode && e.preventDefault()}
    >
      {props.children}
    </Link>
  );
}
