import arrowLeft from '../../assets/icons/Vector-left.svg';
const VectorLeft = ({ moveItemInArrayFromIndexToIndex, index }) => {
  let r = index - 1;
  return (
    <i
      onClick={() => moveItemInArrayFromIndexToIndex(index, r)}
      className="invisible group-hover:visible opacity-0 group-hover:opacity-100 group-hover:hover:transition duration-100 ease-in-out absolute left-0 bottom-24 flex justify-center items-center  bg-black-eel rounded-full w-5 h-5 ml-3"
    >
      <img src={arrowLeft} className="w-3 h-3" />
    </i>
  );
};

export default VectorLeft;
