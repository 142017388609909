import React from 'react';
import { Link } from 'react-router-dom';
import GradeName from './GradeName';
import Thumbnail from './Thumbnail';

import { useGetLessonsQuery } from '../../api/lessons';
import { BigThumbnailSkeleton } from '../Shared/Skeletons';
import { DynamicLink } from '../Shared/DynamicLink';

const Grade = ({
  group,
  description,
  playlist,
  name,
  link,
  maxItems = 4,
  pos = Number.MAX_SAFE_INTEGER,
}) => {
  const { data: lessons = [], isLoading } = useGetLessonsQuery({
    education_group: group,
    lesson_tags: playlist,
    orderby: 'modified',
    per_page: maxItems,
    order: 'desc',
  });

  if (!isLoading && lessons.length === 0) return null;

  return (
    <div className="mx-auto px-0 md:px-9 lg:px-9 xl:px-9 3xl:px-0">
      <GradeName name={name} />
      <div className="inline-block">{description}</div>
      {link ? (
        <DynamicLink to={link}>
          <span className="text-blue-clear font-normal tracking-wide cursor-pointer text-base">
            Explore All {`>`}
          </span>
        </DynamicLink>
      ) : null}

      <div className="grid thumbnails-grid gap-5 500media:thumbnails-grid-500 md:gap-7 lg:gap-4 3xl:gap-7 py-5 lg:thumbnails-grid-lg xl:thumbnails-grid-xl xl:gap-6">
        {isLoading ? (
          <BigThumbnailSkeleton quantity={4} />
        ) : (
          lessons.map((lesson, index) => {
            const { video_thumbnail, video } = lesson.acf;
            const { sizes } = video_thumbnail;

            return (
              <Thumbnail
                key={index}
                image={sizes && sizes['post-thumbnail']}
                video={video}
                {...lesson}
                pos={pos === 0 ? index : Number.MAX_SAFE_INTEGER} //Only set position for first group
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Grade;
