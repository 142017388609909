import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/elevo_logo_white.png';
import LogoBlack from '../../assets/icons/elevo_logo_black.png';
import LoggedUser from './LoggedUser';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { isAuthRequired } from '../../configs/flags';
import { MenuAlt3Icon, MenuIcon } from '@heroicons/react/outline';
import Sidebar from './Sidebar';
import strings from '../../configs/strings.json';
import { Search } from './Search';
import { DynamicLink } from '../Shared/DynamicLink';
import IndependentStudyButton from './IndependentStudyButton';

const Header = () => {
  const [showLinks, setShowLinks] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { oktaAuth } = useOktaAuth() || {};
  const userInfo = useSelector((state) => state.auth.user);

  const closeSidebar = () => {
    setShowLinks(false);
  };

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const logout = async () => {
    oktaAuth.signOut('/');
  };

  return (
    <header className="print:static fixed flex top-0 w-full bg-orange-header print:bg-white-pure pr-5 md:pr-10 z-10 header-height items-center 3xl:pr-20 print:pr-20">
      <nav className="h-16 flex items-center justify-between print:justify-end w-full">
        <DynamicLink to={ROUTES.DASHBOARD}>
          <figure
            className="relative
            w-36
            print:w-52
            h-auto
            ml-5
            500media:ml-10
            3xl:ml-20
            cursor-pointer"
          >
            <img
              src={Logo}
              alt="Sports for learning logo"
              className="rounded-sm w-full h-full object-cover print:hidden"
            />
            <img
              src={LogoBlack}
              alt="Sports for learning logo"
              className="hidden rounded-sm w-full h-full object-cover print:block  "
            />
          </figure>
        </DynamicLink>
        <ul className="hidden 900media:flex list-none print:hidden p-0 m-0">
          <li className="hover-underline-animation uppercase text-white font-normal mx-3 text-sm lg:mx-9 text-shadow hover:text-shadow hover:transition duration-100 ease-in-out tracking-wide cursor-pointer text-white-pure lg:font-size-22px ">
            <DynamicLink to={ROUTES.DASHBOARD}>
              {strings.main_section_label}
            </DynamicLink>
          </li>
          <li className="hover-underline-animation uppercase text-white font-normal mx-3 text-sm lg:mx-9 text-shadow hover:text-shadow hover:transition duration-100 ease-in-out tracking-wide cursor-pointer text-white-pure lg:font-size-22px ">
            <DynamicLink to={ROUTES.COMING_SOON}>
              {strings.playlist_label}
            </DynamicLink>
          </li>
          <li className="hover-underline-animation uppercase text-white font-normal mx-3 text-sm lg:mx-9 text-shadow hover:text-shadow hover:transition duration-100 ease-in-out tracking-wide cursor-pointer text-white-pure lg:font-size-22px ">
            <DynamicLink to={ROUTES.PROGRESS_TRACKER}>
              {strings.progress_tracker_label}
            </DynamicLink>
          </li>
        </ul>
        <div className="relative flex items-center">
          <IndependentStudyButton />
          <div className="hidden 900media:flex 900media:items-center print:hidden">
            {isAuthRequired ? (
              <LoggedUser
                isShow={isShow}
                setIsShow={setIsShow}
                user={userInfo}
              />
            ) : null}
          </div>
          <div
            className={`bg-white-pure border border-gray-700 absolute right-0 top-10 opacity-100 p-5 ${
              isShow ? 'visible' : 'invisible'
            } `}
          >
            <button
              className="uppercase bg-blue-skyish p-2 rounded text-white-pure"
              onClick={logout}
            >
              log out
            </button>
          </div>
          <button
            className={`${
              showLinks ? 'nav-toggle' : 'nav-toggle-reverse'
            } print:hidden 900media:hidden ml-5`}
            onClick={toggleLinks}
          >
            {showLinks ? (
              <MenuAlt3Icon className="w-7 h-7 text-white-pure" />
            ) : (
              <MenuIcon className="w-7 h-7 text-white-pure" />
            )}
          </button>

          <Sidebar
            showLinks={showLinks}
            setShowLinks={setShowLinks}
            closeSidebar={closeSidebar}
            user={userInfo}
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
