import { extractUrlFromEmbed, secondsToMinutes } from '../../helpers/functions';
import { useGetVideoQuery } from '../../api/data';

export function Duration({ video = '' }) {
  let url = extractUrlFromEmbed(video).split('/');
  const token = url[url.length - 1];
  const { data: videoData, isFetching, isLoading } = useGetVideoQuery(token);

  if (isFetching || isLoading) return null;

  return <>{videoData && secondsToMinutes(videoData.duration)}</>;
}
