import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from './Card';

const HeroSlider = ({ data, slidesToShow }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {data &&
        data.acf.lessons.map((item) => <Card key={item.title} {...item} />)}
    </Slider>
  );
};

export default HeroSlider;
