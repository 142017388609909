import arrowRight from '../../assets/icons/Vector-right.svg';
const VectorRight = ({ moveItemInArrayFromIndexToIndex, index }) => {
  let r = index + 1;
  return (
    <i
      onClick={() => moveItemInArrayFromIndexToIndex(index, r)}
      className="right-0 absolute bottom-24 invisible group-hover:visible opacity-0 group-hover:opacity-100 group-hover:hover:transition duration-100 ease-in-out flex justify-center items-center bg-black-eel rounded-full w-5 h-5 mr-3"
    >
      <img src={arrowRight} className="w-3 h-3" />
    </i>
  );
};

export default VectorRight;
