import React from 'react';
import Description from './Description';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { DynamicLink } from '../Shared/DynamicLink';

const Thumbnail = ({ title, sel_skill, image, id, video, pos }) => {
  return (
    <DynamicLink
      className="rounded-sm
        overflow-hidden
        cursor-pointer
        thumb-item"
      to={`/lessons/${id}`}
      force={pos <= 1}
    >
      <LazyLoadImage
        src={image}
        alt="name"
        effect="blur"
        width="100%"
        className="block w-full h-full object-fit"
      />
      <Description title={title.rendered} category={sel_skill} video={video} />
    </DynamicLink>
  );
};

export default Thumbnail;
