import Skeleton from 'react-loading-skeleton';

export function ThumbnailSkeleton({ quantity = 1 }) {
  return (
    <>
      {[...Array(quantity)].map((_, i) => (
        <div
          className="recommended-thumbnail-dimensions-1620media h-22"
          key={i}
        >
          <Skeleton count={1} height={170} />
          <Skeleton count={1} />
        </div>
      ))}
    </>
  );
}

export function BigThumbnailSkeleton({ quantity = 1 }) {
  return (
    <>
      {[...Array(quantity)].map((_, i) => (
        <div
          className="rounded-sm
        overflow-hidden
        cursor-pointer
        w-80
        h-80
        1620media:dashboard-thumbnail-dimensions-1620media
        xl:dashboard-thumbnail-dimensions-xl
        3xl:dashboard-thumbnail-dimensions-3xl"
          key={i}
        >
          <Skeleton count={1} height={200} />
          <Skeleton count={1} width={250} />
        </div>
      ))}
    </>
  );
}
