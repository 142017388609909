import React from 'react';

const InputField = ({ name, label, type, isRequired, register }) => {
  return (
    <label className="flex flex-col text-xs text-gray-label mt-5 w-full">
      {label}
      <input
        {...register(name, { required: isRequired })}
        className="input-field w-full"
        type={type}
      />
    </label>
  );
};

export default InputField;
