import React from 'react';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Description from './Description';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RemoveAction from './RemoveAction';
import VectorLeft from './VectorLeft';
import VectorRight from './VectorRight';

const SecondThumbnail = ({
  editMode = false,
  title,
  category,
  image,
  index,
  video,
  id,
  moveItemInArrayFromIndexToIndex,
  remove,
  date,
  playlist = null,
}) => {
  const history = useHistory();

  const handleRoute = () => {
    let route = `/lessons/${id}`;
    if (playlist !== null) route += `/playlist/${playlist}`;

    history.push(route);
  };

  return (
    <div className="relative rounded-sm overflow-hidden group cursor-pointer xl:playlist-thumbnail-dimensions-xl 3xl:playlist-thumbnail-dimensions-3xl">
      <LazyLoadImage
        onClick={!editMode && handleRoute}
        src={image}
        alt="name"
        effect="blur"
        width="100%"
        className="block w-full xl:h-44 object-fit align-bottom"
      />
      {!editMode ? null : (
        <>
          <VectorLeft
            index={index}
            moveItemInArrayFromIndexToIndex={moveItemInArrayFromIndexToIndex}
          />
          <VectorRight
            index={index}
            moveItemInArrayFromIndexToIndex={moveItemInArrayFromIndexToIndex}
          />
          <RemoveAction id={id} remove={remove} />
        </>
      )}
      <Description
        handleRoute={handleRoute}
        title={title}
        category={category}
        video={video}
        date={date}
      />
    </div>
  );
};

export default SecondThumbnail;
