import React, { useState } from 'react';
import {
  ShareIcon,
  PrinterIcon,
  StarIcon,
  DocumentAddIcon,
} from '@heroicons/react/outline';
import { isAuthRequired } from '../../configs/flags';
import 'react-responsive-modal/styles.css';
import { PlaylistModal } from '../Modal/Playlist';

const Actions = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <PlaylistModal open={open} onClose={onCloseModal} />
      <div className="flex justify-end w-48 col-start-5 col-end-7 justify-self-end">
        <span
          className="flex flex-col items-center mr-3 cursor-pointer"
          onClick={onOpenModal}
        >
          {/* <i className="block bg-no-repeat bg-contain bg-add-playlist-icon-1 w-6 h-6 bg-center " />
          <span className="uppercase text-xs opacity-70 tracking-wider mt-1 font-normal">
            Save
          </span> */}
        </span>

        <button
          className="print:hidden flex flex-col items-center mr-3 cursor-pointer"
          onClick={() => window.print()}
        >
          <PrinterIcon className="w-4 h-4 500media:w-6 500media:h-6 opacity-70" />
          <span className="uppercase text-xs opacity-70 tracking-wider mt-1 font-normal">
            print
          </span>
        </button>
        {/* <span className="flex flex-col items-center mr-3 cursor-pointer">
          <ShareIcon className="w-4 h-4 500media:w-6 500media:h-6 opacity-70" />
          <span className="uppercase text-xs opacity-70 tracking-wider mt-1 font-normal">
            share
          </span>
        </span> */}

        {isAuthRequired ? (
          <span className="flex flex-col items-center cursor-pointer">
            {/*
            <StarIcon className="w-4 h-4 500media:w-6 500media:h-6 opacity-70" />
            <span className="uppercase text-xs opacity-70 tracking-wider mt-1 font-normal">
              favorite
            </span>*/}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default Actions;
