import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const lessonsApi = createApi({
  reducerPath: 'lessonsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_WP_URL + 'wp/v2/',
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Basic c3BvcnRzZmxwcm9kOm1vbmFsaXNh`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLessonById: builder.query({
      query: (id) => `lesson/${id}`,
    }),
    getLessons: builder.query({
      query: (args) => ({ url: 'lesson', params: args }),
    }),
    getGroups: builder.query({
      query: (args) => ({ url: 'education_group', params: args }),
    }),
    getGroupById: builder.query({
      query: (id) => `education_group/${id}`,
    }),
    getOrgPlaylists: builder.query({
      query: () => `lesson_tags`,
    }),
    getOrgPlaylistById: builder.query({
      query: (id) => `lesson_tags/${id}`,
    }),
    getSkillById: builder.query({
      query: (id) => `sel_skill/${id}`,
    }),
  }),
});

export const {
  useGetLessonByIdQuery,
  useGetLessonsQuery,
  useGetSkillByIdQuery,
  useGetGroupsQuery,
  useGetGroupByIdQuery,
  useGetOrgPlaylistsQuery,
  useGetOrgPlaylistByIdQuery,
} = lessonsApi;
