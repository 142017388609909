import { useOktaAuth } from '@okta/okta-react';

import { useEffect } from 'react';
import { storeUser } from './store/auth';
import { useDispatch } from 'react-redux';

import { userRepository } from './repositories';

/**
 * Sets the okta user into redux store
 * @returns {null}
 * @constructor
 */
export default function OktaInit() {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      dispatch(storeUser(null));
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          dispatch(storeUser(info));
          userRepository.upsert(info);
        })
        .catch((err) => console.log(err));
    }
  }, [authState, oktaAuth, dispatch]);

  return null;
}
