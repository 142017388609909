import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const optionsApi = createApi({
  reducerPath: 'optionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_WP_URL + 'acf/v3/',
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Basic c3BvcnRzZmxwcm9kOm1vbmFsaXNh`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: (id) => `options/option`,
    }),
  }),
});

export const { useGetOptionsQuery } = optionsApi;
