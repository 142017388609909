import React from 'react';
import { Duration } from '../Shared/Duration';
import Date from '../Video/Date';

const Description = ({ title, category, video, handleRoute, date }) => {
  return (
    <div className="relative bg-transparent" onClick={handleRoute}>
      <section className="flex justify-between">
        <h1 className="font-bold text-xl capitalize">{title}</h1>
        <span className="visible opacity-100 group-hover:invisible group-hover:opacity-0 group-hover:transition duration-300 ease-in-out absolute right-4 -top-12 font-medium text-sm bg-black-blacker py-1 px-2 rounded-sm text-white-pure">
          <Duration video={video} />
        </span>
      </section>
      <span className="opacity-50 capitalize text-xs">{category}</span>
      <span className="block opacity-50 capitalize text-xs">
        <Date strDate={date} />
      </span>
    </div>
  );
};

export default Description;
