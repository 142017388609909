import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { firebaseConfig as config } from './configs/firebase.credentials';

class Firebase {
  constructor() {
    const firebaseApp = initializeApp(config);
    this.auth = getAuth(firebaseApp);
    this.db = getFirestore(firebaseApp);
  }

  login(token) {
    return signInWithCustomToken(this.auth, token);
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }
}

export default new Firebase();
