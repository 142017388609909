import React, { useEffect, useState } from 'react';
import { useGetOptionsQuery } from '../../api/options';
import ReactLoader from '../ReactLoader';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CarouselCard from './CarouselCard';
import HeroSlider from './HeroSlider';

const Hero = () => {
  const { data, error, isLoading } = useGetOptionsQuery();
  const [size, setSize] = useState(window.innerWidth);
  const [numberOfSlides, setNumberOfSlides] = useState(1);

  const checksize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', checksize);
    return () => window.removeEventListener('resize', checksize);
  }, []);

  useEffect(() => {
    if (size < 500) {
      setNumberOfSlides(1);
    } else if (size >= 500 && size < 768) {
      setNumberOfSlides(2);
    } else if (size >= 768 && size < 1024) {
      setNumberOfSlides(3);
    } else if (size >= 1024 && size < 1280) {
      setNumberOfSlides(4);
    } else if (size >= 1280) {
      setNumberOfSlides(5);
    }
  }, [size]);

  if (isLoading) {
    return (
      <div className="flex justify-center p-40">
        <ReactLoader />
      </div>
    );
  }

  if (error) {
    return <h1 className={'mt-32 ml-5'}>Error loading the content</h1>;
  }

  return (
    <div className="grid grid-cols-1 pt-20 pb-10 px-10 relative background-dashboard-md xl:background-dashboard-xl 3xl:background-dashboard-3xl">
      <HeroSlider data={data} slidesToShow={numberOfSlides} />
    </div>
  );
};

export default Hero;
