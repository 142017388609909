import * as ROUTES from '../constants/routes';

const OKTA_DOMAIN = 'elevo3.jhonny.cc';
const CLIENT_ID = '0oa20z224cWRLnbT85d7';

const config = {
  issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
  clientId: CLIENT_ID,
  redirectUri: `${window.location.origin}${ROUTES.LOGIN_CALLBACK}`,
  scopes: ['openid', 'profile', 'email'],
};

export default config;
