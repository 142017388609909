import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  org: '',
  params: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    storeOrg: (state, action) => {
      state.org = action.payload;
    },
    storeParams: (state, action) => {
      state.params = action.payload;
    },
  },
});

export const { storeOrg, storeParams } = configSlice.actions;
export default configSlice.reducer;
