import thrashIcon from '../../assets/icons/thrash-icon.png';
const RemoveAction = ({ id, remove }) => {
  return (
    <i
      onClick={() => remove(id)}
      className="invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:transition duration-300 absolute block rounded-full bg-red-500 z-1 p-2 top-2 right-2"
    >
      <img src={thrashIcon} className="w-3 h-3" alt="thrash icon" />
    </i>
  );
};

export default RemoveAction;
