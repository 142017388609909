import Repository from './base';
import fb from '../firebase';
import {
  updateDoc,
  arrayUnion,
  arrayRemove,
  doc,
  setDoc,
  collection,
  serverTimestamp,
  orderBy,
} from 'firebase/firestore';

const PLAYLIST_DEFAULTS = {
  NY_FAVORITES: 'favorites',
};

class PlaylistRepository extends Repository {
  collection = 'playlists';

  async insert(data) {
    const firebaseUser = this.fs.getCurrentUser();

    if (!data || !firebaseUser) return;

    const payload = {
      ownerId: firebaseUser.uid,
      updatedAt: serverTimestamp(),
    };

    if (data.title) payload.title = data.title;
    if (data.lessons) payload.lessons = data.lessons;

    const newPlayListRef = doc(collection(this.fs.db, this.collection));
    await setDoc(newPlayListRef, payload);
  }

  async getByTitle(titleQuery) {
    return await this.searchOneByField('title', titleQuery);
  }

  async getOwned() {
    const firebaseUser = this.fs.getCurrentUser();

    if (!firebaseUser) return [];
    return await this.searchByField(
      'ownerId',
      firebaseUser.uid,
      '==',
      orderBy('title')
    );
  }

  async addLesson(playlistId, lesson) {
    const playListRef = this.getRef(playlistId);
    await updateDoc(playListRef, {
      lessons: arrayUnion(lesson),
    });
  }

  async removeLesson(playlistId, lesson) {
    const playListRef = this.getRef(playlistId);
    await updateDoc(playListRef, {
      lessons: arrayRemove(lesson),
    });
  }
}

export const playlistRepository = new PlaylistRepository(fb);
