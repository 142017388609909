import { useEffect } from 'react';
import fb from './firebase';
import { useLocation } from 'react-router-dom';
import { isAuthRequired } from './configs/flags';

async function oktaToFirebaseToken(oktaAuth) {
  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) return null;

  const firebaseTokenResponse = await fetch(
    process.env.REACT_APP_CLOUD_FUNCTIONS + '/api/firebaseCustomToken',
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return await firebaseTokenResponse.text();
}

/**
 * Takes an Okta accessToken and auths into firebase
 * using a cloud function that validates and generates
 * a valid firebase auth token.
 * @param oktaAuth
 */
export function useFirebaseOktaAuth(oktaAuth, firebaseInitialized) {
  useEffect(() => {
    if (!firebaseInitialized || !isAuthRequired) return;

    oktaToFirebaseToken(oktaAuth)
      .then((firebaseToken) => firebaseToken && fb.login(firebaseToken))
      .catch((err) => console.log(err));
  }, [oktaAuth, firebaseInitialized]);
}

export function useQuery(value = null) {
  const search = new URLSearchParams(useLocation().search);
  return value === null ? search.toString() : search.get(value);
}
