import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dataApi = createApi({
  reducerPath: 'dataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FIREBASE_API,
  }),
  endpoints: (builder) => ({
    getVideo: builder.query({
      query: (token) => `video/${token}`,
    }),
  }),
});

export const { useGetVideoQuery } = dataApi;
