import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: '',
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    search: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { search } = lessonsSlice.actions;
export default lessonsSlice.reducer;
