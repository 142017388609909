const GradeName = ({ name }) => {
  return (
    <>
      <h3 className="inline-block mr-3 text-3xl 3xl:text-4xl tracking-normal font-semibold">
        {name}
      </h3>
    </>
  );
};

export default GradeName;
