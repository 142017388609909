import React from 'react';
import Category from '../Video/Category';
import Date from '../Video/Date';
import { DynamicLink } from '../Shared/DynamicLink';
import { Duration } from '../Shared/Duration';

const Recommended = ({
  category,
  title,
  date,
  thumbnail,
  id,
  playlist = null,
  video,
}) => {
  const { sizes } = thumbnail;

  let route = `/lessons/${id}`;
  if (playlist !== null) route += `/playlist/${playlist}`;

  return (
    <DynamicLink to={{ pathname: route }}>
      <div className="rounded overflow-hidden md:w-full md:h-full lg:recommended-thumbnail-dimensions-lg xl:recommended-thumbnail-dimensions-1620media 3xl:recommended-thumbnail-dimensions-1620media cursor-pointer">
        <div className="relative">
          <img
            src={sizes && sizes['post-thumbnail']}
            alt=""
            className="w-full h-22"
          />
          <span className="block absolute m-2 lg:m-2 bottom-0 right-0 bg-black-blacker text-white rounded-sm py-1 px-2 tracking-wider text-white-pure text-xs">
            <Duration video={video} />
          </span>
        </div>
        <h4 className="capitalize font-bold text-lg">{title}</h4>
        <p className="capitalize text-xs text-gray-carbon">
          <Category id={category} />
        </p>
        <span className="capitalize text-xs text-gray-carbon">
          <Date strDate={date} />
        </span>
      </div>
    </DynamicLink>
  );
};

export default Recommended;
