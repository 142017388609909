import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import configReducer from './config';
import lessonReducer from './lesson';

import { lessonsApi } from '../api/lessons';
import { optionsApi } from '../api/options';
import { dataApi } from '../api/data';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    config: configReducer,
    lesson: lessonReducer,
    [lessonsApi.reducerPath]: lessonsApi.reducer,
    [optionsApi.reducerPath]: optionsApi.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(lessonsApi.middleware)
      .concat(optionsApi.middleware),
});
