import React from 'react';

const Category = ({ tag, tag_color }) => {
  return (
    <h4
      className={`absolute
      bottom-0
      text-white-pure
      py-1 px-3 m-2 ${tag_color}
      rounded
      3xl:ml-8 ml-5 mb-5 3xl:text-base text-xs
      tracking-wider
      font-medium capitalize`}
    >
      {tag}
    </h4>
  );
};

export default Category;
