import React from 'react';
import Category from '../Video/Category';
import { Duration } from '../Shared/Duration';

const Description = ({ title, category, video = '' }) => {
  return (
    <div className="py-2 bg-transparent">
      <section className="flex justify-between">
        <h1 className="font-bold text-base capitalize ">{title}</h1>
        <span className="font-medium text-base">
          <Duration video={video} />
        </span>
      </section>
      <span className="opacity-50 capitalize text-base">
        <Category id={category} />
      </span>
    </div>
  );
};

export default Description;
