const urlRegex = /(https?:\/\/[^']*)/;
const DEFAULT_VIDEO = 'https://embed.mindstamp.io/embed/tYPLFjfaGhZD';

export function extractUrlFromEmbed(embed) {
  return (embed || DEFAULT_VIDEO).match(urlRegex)[1];
}

export function secondsToMinutes(seconds) {
  return new Date(seconds * 1000).toISOString().substr(14, 5);
}
