import React from 'react';
import NoAvatarIcon from '../../assets/icons/inexistent_user.jpg';

const LoggedUser = ({ setIsShow, isShow, user }) => {
  const showUserModal = (e) => {
    e.preventDefault();
    setIsShow(!isShow);
  };

  return (
    <>
      <img
        src={NoAvatarIcon}
        alt="user profile"
        className="mr-0 900media:mr-2 h-10 w-10 rounded-full cursor-pointer"
        onClick={showUserModal}
      />
      <span className="align-bottom 900media:text-white-pure text-black-eel text-base font-normal">
        {(user && user.given_name) || ''}
      </span>
    </>
  );
};

export default LoggedUser;
