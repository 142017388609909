import { Modal } from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { playlistRepository } from '../../repositories';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export function PlaylistModal({ open, onClose }) {
  const { id: lessonId } = useParams();

  const [playlist, setPlaylist] = useState('');
  const [userPlaylists, setUserPlaylists] = useState([]);
  const userInfo = useSelector((state) => state.auth.user);
  const [listForLesson, setListForLesson] = useState([]);

  const loadPlaylists = async () => {
    const data = await playlistRepository.getOwned();
    setUserPlaylists(data);
  };

  useEffect(() => {
    setListForLesson(
      userPlaylists
        .filter((p) => p.lessons.includes(parseInt(lessonId)))
        .map((p) => p.id)
    );
  }, [userPlaylists]);

  useEffect(() => {
    loadPlaylists();
  }, [userInfo, lessonId]);

  const createPlaylist = () => {
    playlistRepository
      .insert({ title: playlist, lessons: [parseInt(lessonId)] })
      .then((da) => {
        toast.success(`Playlist ${playlist} created`);
        loadPlaylists();
        setPlaylist('');
      });
  };

  const handlePlaylist = (e) => {
    setPlaylist(e.target.value);
  };

  const handleLessonChange = (playlistId, value) => {
    if (value) {
      playlistRepository.addLesson(playlistId, parseInt(lessonId)).then((r) => {
        setListForLesson([...listForLesson, playlistId]);
        toast.success('Lesson added');
      });
    } else {
      playlistRepository
        .removeLesson(playlistId, parseInt(lessonId))
        .then((r) => {
          setListForLesson(listForLesson.filter((p) => p !== playlistId));
          toast.success('Lesson removed');
        });
    }
  };

  const closeIcon = (
    <div className="flex px-2 py-0 rounded-full border-2 border-orange-header">
      <span className="block text-orange-header transform scale-x-150 align-bottom">
        X
      </span>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      closeIcon={closeIcon}
      blockScroll={false}
      classNames={{
        modal:
          'rounded-xl border-blue-stroke border-4 border-opacity-50 text-left w-2/6',
      }}
    >
      <h2 className={'font-bold  py-2'}>Save video to:</h2>
      <div className="h-80 overflow-y-scroll border-b-2 border-black-blacker border-t-2 p-1">
        <ul>
          {userPlaylists.length > 0 ? (
            userPlaylists.map((p) => (
              <li key={p.id} className={'my-2'}>
                <label>
                  <input
                    type={'checkbox'}
                    className={'w-4 h-4 align-middle'}
                    checked={listForLesson.includes(p.id)}
                    onChange={(e) => {
                      handleLessonChange(p.id, e.target.checked);
                    }}
                  />{' '}
                  <span className={'ml-2'}>{p.title}</span>
                </label>
              </li>
            ))
          ) : (
            <li className={'py-2'}>No playlists found.</li>
          )}
        </ul>
      </div>
      <div>
        <h2 className={'font-bold py-2 mt-1'}>Create Playlist:</h2>
        <input
          className={
            'border-gray-stone border-2 rounded-sm border-opacity-50 w-full outline-none py-2 px-2 text-xs'
          }
          onChange={handlePlaylist}
          value={playlist}
          placeholder="Playlist Name"
        />
        <button
          className={
            'bg-orange-header font-medium px-8 py-1 rounded-lg text-white-fantasy block mt-4 disabled:opacity-50'
          }
          onClick={createPlaylist}
          disabled={playlist.length === 0}
        >
          Create
        </button>
      </div>
    </Modal>
  );
}
