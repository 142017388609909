import React from 'react';

const urlRegex = /(https?:\/\/[^']*)/;

const VideoEmbed = ({
  url = 'https://embed.mindstamp.io/embed/tYPLFjfaGhZD',
  params = '',
}) => {
  const cleanUrl = (
    url || 'https://embed.mindstamp.io/embed/tYPLFjfaGhZD'
  ).match(urlRegex)[1];

  return (
    <div
      className="print:hidden"
      style={{
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
        borderRadius: '3px',
      }}
    >
      <iframe
        className="w-full"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          height: '100%',
          minHeight: 'unset',
          minWidth: 'unset',
          border: '0',
        }}
        title="mindstamp frame"
        src={cleanUrl + '?' + params}
        allowFullScreen
        allow="encrypted-media; microphone; camera "
        scrolling="no"
      />
    </div>
  );
};

export default VideoEmbed;
