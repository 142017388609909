import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const DateLabel = ({ strDate }) => {
  const now = dayjs.utc();
  const modified = dayjs(strDate + 'z').utc();

  return <span className="inline-block text-sm">{modified.from(now)}</span>;
};

export default DateLabel;
