export const LOGIN_CALLBACK = '/login/callback';
export const DASHBOARD = '/';
export const EXPLORE_ALL = '/grades/:grade';
export const ORG_PLAYLIST = '/grades/:grade/playlist/:playlist';
export const VIDEO_DETAILS = '/lessons/:id';
export const VIDEO_DETAILS_PLAYLIST = '/lessons/:id/playlist/:playlist';

export const PLAYLISTS = '/playlists';
export const COMING_SOON = '/coming-soon';
export const PROGRESS_TRACKER = '/progress-tracker';
export const CHANGE_PASSWORD = '/change-password';
export const SEARCH = '/search';
