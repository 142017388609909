import React, { useEffect, useState, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import strings from './configs/strings.json';

//store
import { storeOrg, storeParams } from './store/config';

//routes
import * as Routes from './constants/routes';

//components
import { Header } from './components';

//pages
import { isAuthRequired } from './configs/flags';

//okta
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './configs/okta.credentials';
import { useFirebaseOktaAuth, useQuery } from './hooks';
import OktaInit from './oktaUser';
import firebase from './firebase';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';

const oktaAuth = isAuthRequired ? new OktaAuth(config) : null;

// Only use Okta components if auth is required
function ConfigurableSecurity(props) {
  if (!isAuthRequired) return <>{props.children}</>;
  return <Security {...props} />;
}

function ConfigurableRouter(props) {
  if (!isAuthRequired) return <Route {...props} />;
  return <SecureRoute {...props} />;
}

// noinspection JSCheckFunctionSignatures
const routes = [
  {
    component: React.lazy(() => import('./pages/Dashboard')),
    path: Routes.DASHBOARD,
  },
  {
    component: React.lazy(() => import('./pages/VideoDetails')),
    path: Routes.VIDEO_DETAILS,
  },
  {
    component: React.lazy(() => import('./pages/ExploreAll')),
    path: Routes.EXPLORE_ALL,
  },
  {
    component: React.lazy(() => import('./pages/OrgPlaylist')),
    path: Routes.ORG_PLAYLIST,
  },
  {
    component: React.lazy(() => import('./pages/CommingSoon')),
    path: Routes.COMING_SOON,
  },
  {
    component: React.lazy(() => import('./pages/VideoDetails')),
    path: Routes.VIDEO_DETAILS_PLAYLIST,
  },
  {
    component: React.lazy(() => import('./pages/ChangePassword')),
    path: Routes.CHANGE_PASSWORD,
  },
  {
    component: React.lazy(() => import('./pages/Search')),
    path: Routes.SEARCH,
  },
  {
    component: React.lazy(() => import('./pages/ProgressTracker')),
    path: Routes.PROGRESS_TRACKER,
  },
];

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery('org');
  const allParams = useQuery();
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useFirebaseOktaAuth(oktaAuth, firebaseInitialized);

  useEffect(() => {
    if (query) dispatch(storeOrg(query));
  }, [query]);

  useEffect(() => {
    if (allParams) dispatch(storeParams(allParams));
  }, [allParams]);

  useEffect(() => {
    document.title = strings.title;

    isAuthRequired &&
      firebase.isInitialized().then((val) => {
        setFirebaseInitialized(true);
      });
  }, []);

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    const newUrl = toRelativeUrl(originalUri || '/', window.location.origin);
    history.replace(newUrl);
  };

  return (
    <ConfigurableSecurity
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      {isAuthRequired ? <OktaInit /> : null}
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <Switch>
        <Suspense fallback={<div>Loading ... </div>}>
          {routes.map((r, key) => (
            <ConfigurableRouter exact {...r} key={key} />
          ))}
          <Route component={LoginCallback} path={Routes.LOGIN_CALLBACK} />
        </Suspense>
      </Switch>
    </ConfigurableSecurity>
  );
}

export default App;
