import Repository from './base';
import { doc, setDoc } from 'firebase/firestore';
import fb from '../firebase';

class UserRepository extends Repository {
  collection = 'users';

  upsert(data) {
    const firebaseUser = this.fs.getCurrentUser();
    if (!data || !firebaseUser) return;

    const userPayload = {
      email: data.email,
      firstName: data.given_name,
      lastName: data.family_name,
      gradeLevel: data.grade_level || '',
      photoUrl: data.photo_url || '',
    };

    const cityRef = doc(this.fs.db, 'users', firebaseUser.uid);
    setDoc(cityRef, userPayload, { merge: true });
  }
}

export const userRepository = new UserRepository(fb);
